<template>
    <section class="map-container">
        <div>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1183619.6672817965!2d53.83718328702146!3d24.18973897166969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e48dfb1ab12bd%3A0x33d32f56c0080aa7!2sUnited%20Arab%20Emirates!5e0!3m2!1sen!2sca!4v1731031487572!5m2!1sen!2sca"
                style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="wrapper">
            <button class="pin-btn" @click="openModal">
                <img v-if="!isModal" class="pin-img"
                    src="https://cdn.theblueground.com/website/static/img/property.27381fe4dcfd550fbb53.svg"
                    alt="marker">
                <img v-else class="pin-img"
                    src="https://cdn.theblueground.com/website/static/img/property-blue.cab25e5a00d858804158.svg"
                    alt="marker">
            </button>
            <div class="property-card" v-if="isModal">
                <div class="header">
                    <div class="icon">
                        <img src="https://cdn.theblueground.com/website/static/img/infowindow-property.e15d781204792e12128c.svg"
                            alt="property">
                    </div>
                    <h3>Midtown</h3>
                    <button class="close-button" @click="closeModal">
                        <img src="https://cdn.theblueground.com/website/static/img/x-denim.5fda643db3e2f57585c8.svg"
                            alt="close">
                    </button>
                </div>
                <div class="image-wrapper">
                    <div class="badge">Partner Managed</div>
                    <img src="https://photos2.theblueground.com/736/partner-network/hometeam/guesty-listing-images/production/original_1040509681264543987_1795859893.jpg?quality=low"
                        alt="Living Room" class="property-image" />
                    <div class="heart-icon">
                        <img src="https://cdn.theblueground.com/website/static/img/heart-white-border-off.2c6fae4378b97ef6266f.svg"
                            class="" alt="wishlist property" width="26" height="24">
                    </div>
                </div>
                <div class="property-details">
                    <h4>937 Meda Street</h4>
                    <span class="property-id">ID: 1023322P</span>
                    <div class="property-icons">
                        <div class="icon">
                            <img src="https://cdn.theblueground.com/website/static/img/QueenBed-denim.217903d65d7b9a1aa615.svg"
                                alt="bedrooms">
                            <span>3</span>
                        </div>
                        <div class="icon">
                            <img src="https://cdn.theblueground.com/website/static/img/Bathtub-denim.d36e77775512aa20db54.svg"
                                alt="bathrooms">
                            <span>2, 1 WC</span>
                        </div>
                    </div>
                    <p class="price">from $4,770/mo incl. utilities</p>
                    <p class="availability">Available 12 Nov 2024</p>
                </div>
            </div>
        </div>
        <button class="places-toggle-btn toolbar-button d-sm-block d-md-none" @click="openPlacesModal"><img
                src="https://cdn.theblueground.com/website/static/img/pin.7201485a6afdd39066b2.svg"
                alt="picture">Places</button>
        <div v-if="isPlaces"
            class="nav-btns-wrapper d-flex justify-content-between flex-wrap bg-light bg-gradient shadow">
            <button class="d-sm-block d-md-none w-100 bg-light bg-gradient border-0 shadow p-4 position-relative"
                @click="openPlacesModal"> <img
                    src="https://cdn.theblueground.com/website/static/img/x-denim.5fda643db3e2f57585c8.svg" alt="image"
                    class="position-absolute top-50 start-0 translate-middle-y ms-3"> Places</button>
            <button class="toolbar-button m-2"> <img
                    src="https://cdn.theblueground.com/website/static/img/arrow-small-right-blue-main.f23c989a3e56c248a5cf.svg"
                    alt="toolbar-img"> Collapse map</button>
            <button class="toolbar-button m-2"> <img
                    src="https://cdn.theblueground.com/website/static/img/supermarket.06ae8a016964a197e04d.svg"
                    alt="toolbar-img"> Supermarkets</button>
            <button class="toolbar-button m-2"><img
                    src="https://cdn.theblueground.com/website/static/img/parking.61f10023efcee3e7acf9.svg"
                    alt="toolbar-img"> Parking lots</button>
            <button class="toolbar-button m-2"><img
                    src="https://cdn.theblueground.com/website/static/img/gym.43b6f01a6cd5e1fe0e3b.svg"
                    alt="toolbar-img"> Gyms</button>
            <button class="toolbar-button m-2"><img
                    src="https://cdn.theblueground.com/website/static/img/school.462367a8ad9084eab017.svg"
                    alt="toolbar-img"> Schools/Universities</button>
            <button class="toolbar-button m-2"><img
                    src="https://cdn.theblueground.com/website/static/img/restaurant.7fd525e3f418d69ebfc7.svg"
                    alt="toolbar-img"> Restaurants</button>
            <button class="toolbar-button m-2"> <img
                    src="https://cdn.theblueground.com/website/static/img/arrow-big-right-blue-main.fc049eddfe840cfec961.svg"
                    alt="toolbar-img"> </button>
        </div>
    </section>

</template>

<script setup>
import { ref } from 'vue';

const isModal = ref(false);
const isPlaces = ref(true);

const closeModal = () => {
    isModal.value = false;
}
const openModal = () => {
    isModal.value = true;
}

const openPlacesModal = () => {
    isPlaces.value = !isPlaces.value;
}
</script>


<style scoped>
.places-toggle-btn {
    position: absolute;
    top: 100px;
    left: 10px;
}

.map-container {
    position: relative;
}

iframe {
    width: 100%;
    height: 100vh;
}

.pin-btn {
    width: auto;
    height: auto;
    position: absolute;
    top: 70%;
    left: 60%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: none;
    border-radius: 50%;
}

.pin-img {
    cursor: pointer;
}

.nav-btns-wrapper {
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.toolbar-button {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    border: 1px solid #d1d5db;
    border-radius: 20px;
    background-color: white;
    color: #374151;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.property-card {
    font-family: Arial, sans-serif;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 350px;
    overflow: hidden;
    position: absolute;
    top: 40px;
    left: 40px;
    background-color: white;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f7f7;
    padding: 10px;
}

.icon svg {
    width: 24px;
    height: 24px;
    fill: #2c3e50;
}

.close-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.image-wrapper {
    position: relative;
}

.property-image {
    width: 100%;
    height: auto;
    display: block;
}

.badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #fff;
    color: #0078d4;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}

.heart-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.heart-icon svg {
    width: 24px;
    height: 24px;
    fill: #ff6b6b;
    cursor: pointer;
}

.property-details {
    padding: 15px;
}

.property-details h4 {
    font-size: 18px;
    font-weight: bold;
}

.property-id {
    font-size: 12px;
    color: #777;
}

.property-icons {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.property-icons .icon {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.property-icons svg {
    width: 20px;
    height: 20px;
    fill: #555;
}

.price {
    font-size: 16px;
    color: #2c3e50;
    margin-top: 10px;
}

.availability {
    font-size: 14px;
    color: #777;
    margin-top: 5px;
}
</style>